import React, { Component } from "react"
import { graphql } from 'gatsby'
import WorkCarousel from '../components/workCarousel'
import Team from '../components/team'
import Blog from '../components/blog'
import HeroBanner from '../components/heroBanner'
import About from '../components/about'
import Layout from '../components/layout'
import { hasCookie } from '../services/cookies';

class Homepage extends Component {
  render() {
    const data = this.props.data;

    if (hasCookie() == true) {
      return (
        <Layout>
          <div className="homepage-template">
            {data !== undefined &&
              <div className="banner"><HeroBanner data={data.wpPage} /></div>
            }
            <div className="work-ctn">
              {data !== undefined &&
                <WorkCarousel works={data.allWpProject}
                              data={data.wpPage}
                />
              }
            </div>
            <div className="about-ctn">
              {data !== undefined &&
                <About data={data.wpPage} />
              }
            </div>
            <div className="team-ctn">
              {data !== undefined &&
                <Team data={data.wpPage} />
              }
            </div>
            <div className="blog-ctn">
              {data !== undefined &&
                <Blog blogs={data.allWpPost} />
              }
            </div>
          </div> {/* End .homepage-template */}
        </Layout>
      );
    } else {
      return (
        <></>
      )
    }
  }
}

export default Homepage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    wpPage(slug: {eq: "home"}) {
      id
      slug
      homepageACF {
        heroBanner {
          typeOfBanner
          heroVideo
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                  sizes: "(min-width: 1920px) 1920px, 100vw"
                )
              }
            }
            sourceUrl
            altText
          }
        }
        workSection {
          sectionTitle
          sectionSubtitle
          buttonText
          buttonLink
        }
        aboutUsSection {
          sectionTitle
          sectionSubtitle
          twoColumnContent {
            title
            subtitle
            description
            visualElement {
              id
              altText
              sourceUrl
            }
          }
          image {
            altText
            sourceUrl
            id
          }
        }
        teamSection {
          sectionTitle
          sectionSubtitle
          teamPicture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                  sizes: "(min-width: 1920px) 1920px, 100vw"
                )
              }
            }
            sourceUrl
          }
          buttonText
          buttonLink
          column1Title
          column1Content {
            listText
          }
          column2Title
          column2Content {
            listText
          }
        }
      }
    }
    allWpProject(limit: 8) {
      edges {
        node {
          id
          slug
          title
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                    sizes: "(min-width: 1920px) 1920px, 100vw"
                  )
                }
              }
              sourceUrl
            }
          }
        }
      }
    }
    allWpPost(limit: 3) {
      edges {
        node {
          id
          slug
          title
          content
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                    sizes: "(min-width: 1920px) 1920px, 100vw"
                  )
                }
              }
              sourceUrl
            }
          }
        }
      }
    }
  }
`
