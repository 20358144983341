import React, { Component } from 'react';
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class Blog extends Component {
  render() {
    const data = this.props.blogs;

    return (
      // End .ctn-main.mobile-padding
      <div className="ctn-main mobile-padding">
        <h2 className="colored uk-text-center">From the blog</h2>

        <div className="blog-content uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small" data-uk-grid data-uk-height-match="target: h3">
          {data.edges.map(({ node }) => (
            <div key={node.id} className="uk-margin-bottom">
              <Link to={`/blog/${node.slug}/`}>
                <div className="uk-card-media-top uk-cover-container">
                  {node.featuredImage.node.localFile != null &&
                    node.featuredImage.node.localFile.childImageSharp != null ? (
                    <GatsbyImage image={getImage(node.featuredImage.node.localFile)} />
                  ) : (
                    <img className="imgPath" src={node.featuredImage.node.sourceUrl} alt={node.featuredImage.node.altText} />
                  )}
                </div> {/* End .uk-card-media-top.uk-cover-container */}
                <div className="uk-margin-top">
                    <h3 className="colored" dangerouslySetInnerHTML={{ __html: node.title }} />
                    <div className="summary">
                      <span>Read more</span>
                    </div>
                </div>
                {/* End .uk-margin-top */}
              </Link>
            </div> // End .uk-margin-bottom
          ))}
        </div> {/* End .blog-content.uk-child-width-1-1.uk-child-width-1-2@s.uk-child-width-1-3@m.uk-grid-small */}

        <Link className="button button-reverse space-around centered" to="/blog">
          <span className="top left"></span>
          <span className="bottom left"></span>
          Check more articles <i className="right-triangle"></i>
          <span className="top right"></span>
          <span className="bottom right"></span>
        </Link>
      </div>
    );
  }
}

export default Blog;
