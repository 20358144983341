import React, { Component } from 'react';

class About extends Component {
  render() {

    const aboutAcf = this.props.data.homepageACF.aboutUsSection;

    return (
      <div>
        <div className="about-banner ctn-large">
          <h2 className="colored mobile-padding uk-h1">
            {aboutAcf.sectionTitle}
            {aboutAcf.sectionSubtitle !== "" &&
              <span>{aboutAcf.sectionSubtitle}</span>
            }
          </h2>
          {aboutAcf.image !== null &&
            <div className="visual-elem">
              <img src={aboutAcf.image.sourceUrl} alt={aboutAcf.image.altText} />
            </div>
          }
        </div>
        <div className="block-ctn">
        {aboutAcf.twoColumnContent.map((content, index) => {
          return (
            <div key={index} className="ctn-main about-grid uk-grid-collapse uk-child-width-1-1 uk-child-width-1-2@m" data-uk-grid>
              <div className="content mobile-padding">
                  {content.subtitle !== "" &&
                    <div className="uk-text-large uk-text-muted">{content.subtitle}</div>
                  }
                  {content.title !== "" &&
                    <h2 className="colored uk-margin-remove-top">{content.title} </h2>
                  }

                {content.description !== "" &&
                  <div className="about-content" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                }
              </div> {/* End .content */}
              {content.visualElement !== null &&
                <div className="visual-elem uk-cover-container">
                  <img data-uk-cover className="imgPath" src={content.visualElement.sourceUrl} alt={content.visualElement.altText} />
                </div>
              }
            </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default About;
