import React, { Component } from 'react';
import { Link } from 'gatsby'
import TextLoop from 'react-text-loop'
import { withPrefix } from 'gatsby-link';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class Team extends Component {
  render() {
    const teamAcf = this.props.data.homepageACF.teamSection;

    return (
      <div>
        <div className="team-banner">
          {teamAcf.teamPicture != null &&
            teamAcf.teamPicture.localFile != null &&
            teamAcf.teamPicture.localFile.childImageSharp != null ? (
            <GatsbyImage image={getImage(teamAcf.teamPicture.localFile)} />
          ) : (
            teamAcf.teamPicture !== null && <img className="imgPath" src={teamAcf.teamPicture.sourceUrl} alt={teamAcf.teamPicture.altText} />
          )}
          <div className="team-banner__title">
            <h2 className="colored mobile-padding uk-text-center uk-margin-remove-botton">{teamAcf.sectionTitle}
              <span className="animated">
                <span>we are </span>
                <TextLoop speed={3000} adjustingSpeed={1000}
                  children={[
                    "a team",
                    "a family",
                    "fun folks"
                  ]}
                  />
              </span>
            </h2>
          </div>
          <div className="team-content">
            <Link className="button uk-text-large uk-text-bold" to={teamAcf.buttonLink}>
              <span className="top left"></span>
              <span className="bottom left"></span>
              {teamAcf.buttonText}
              <span className="top right"></span>
              <span className="bottom right"></span>
            </Link>

          </div>
        </div>
        <div className="pros-cons-ctn ctn-main">
          <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-grid-match ctn-large uk-padding uk-background-default uk-card uk-card-default push-up-small" data-uk-grid>
            <div className="left">
              <div className="uk-card">
                <h3 className="colored uk-text-center">{teamAcf.column1Title}</h3>
                <ul>
                  {teamAcf.column1Content.map((content, index) => {
                    return (
                      <li key={index}><img src={withPrefix("img/plus-icon.png")} alt="Plus icon" />{content.listText}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="uk-card">
                <h3 className="colored uk-text-center">{teamAcf.column2Title}</h3>
                <ul>
                  {teamAcf.column2Content.map((content, index) => {
                    return (
                      <li key={index}><img src={withPrefix("img/minus-icon.png")} alt="Minus Icon" />{content.listText}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
