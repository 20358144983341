import React, { Component } from 'react';
import { withPrefix } from 'gatsby-link';
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TextLoop from 'react-text-loop';

class HeroBanner extends Component {
  render() {
    const heroBannerACF = this.props.data.homepageACF.heroBanner;

    return (
      <div>
        <div className="positioned-ctn">
          { heroBannerACF.typeOfBanner === "image" ? (
            heroBannerACF.heroImage.localFile != undefined ? (
              <GatsbyImage image={getImage(heroBannerACF.heroImage.localFile)} />
            ) : (
              <img className="imgPath" src={heroBannerACF.heroImage.sourceUrl} alt={heroBannerACF.heroImage.altText} />
            )
          ) : (
            heroBannerACF.typeOfBanner === "video" &&
              <div className="video-ctn uk-cover-container" data-uk-height-viewport>
                <iframe title="Video" data-uk-cover data-uk-responsive data-uk-video="automute: true" src={'https://www.youtube-nocookie.com/embed/'+heroBannerACF.heroVideo+'?rel=0&amp;playlist='+heroBannerACF.heroVideo+'&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1'} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
              </div>
          )}
        </div>
        <div className="ctn-main">
        	<div className="banner__content">
            <h1 className="uk-text-uppercase uk-heading-xlarge space-around">
              <span>We are</span>
          		Digital
              <span className="animated">
                <TextLoop speed={3000} adjustingSpeed={1000}
                    children={[
                        "Creatives",
                        "Developers",
                        "Innovators",
                        "Explorers"
                    ]}
                />
              </span>
            </h1>
            <Link className="button centered space-around" to="/project-brief">
              <span className="top left"></span>
              <span className="bottom left"></span>
              Let's start a project!
              <span className="top right"></span>
              <span className="bottom right"></span>
            </Link>
        	</div>
        </div>
        <img className="arrow-down" src={withPrefix("img/arrow-down.png")} alt="Arrow pointing down"/>
      </div>
    );
  }
}

export default HeroBanner;
